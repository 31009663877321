.text {
    width: max(70%, 80vmin);
    font-size: max(2.5rem, 2.5vmax);
    margin: 0;
    font-weight: 200;
    text-align: left;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: center;
    height: auto;
    width: 100%;
    margin: 2vh 0 2vh 0;
    text-align: center;
}

#header {
    font-size: max(3rem, 4vmax);
    font-weight: 300;
    text-align: center;
}