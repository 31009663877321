#heading {
    font-size: max(5vmax, 3.5rem);
    color: #2c2c2c;
    text-align: center;
    margin: 20px;
    font-weight: 300;
}

#subheading {
    font-size: 2vmax;
    color: #2c2c2c;
    text-align: center;
    margin: 0px;
    font-weight: 200;
}

#headers {
    margin: 0;
}

#content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 2vmin;
    grid-row-gap: 2vmin;
    width: max(60vw, 80vmin);
    height: 50vh;
    border-radius: 10px;
    background-color: rgba(69, 69, 69, 0.13);
    backdrop-filter: blur(10px);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    padding: 2em;
    margin: 20px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #2c2c2c;
    border-radius: 25px;
    width: 100%;
    height: 100%;
    font-size: 3em;
    transition: all 0.2s ease-in-out;
    flex-wrap: wrap;
    min-width: 0;
    min-height: 0;
}

.content:hover {
    background-color: rgba(69, 69, 69, 0.13);
    backdrop-filter: blur(10px);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

}

.content h2 {
    font-size: max(2vw, 2.5vmin);
    font-weight: 200;
    margin: 0;
    text-align: center;
}

.content svg {
    width: 4vmax;
    height: 4vmax;
    fill: #900000;
}

.contentHeading {
    margin: 5px;
    font-size: 2vw;
}

#root, .Homepage {
    height: 100%;
    width: 100%;
}

.Homepage {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
}