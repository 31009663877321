.Map {
  text-align: center;
  width: 100%;
}

.Map div:not(#content) {
  height: 90vh;
}

#root, .App, .App div {
  height: 100%;
  width: 100%;
}

#spline {
  width: 100%;
  height: 100vh;
}

.heading {
  font-size: 4em;
  text-align: center;
  color: #efefef;
  position: absolute;
  margin: 1vw;
}

#content {
  display: none;
}

#up {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
}

.switch {
  position: absolute;
  top: 0;
  left: max(15%, 10vmin);
  width: 100%;
  transform: translateY(100%);
}