.fullDiv {
    height: 100vh;
    width: 100vw;
}

#appRoot {
    height: auto;
    width: 100%;
}

#scrollTop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    background-color: rgba(208, 208, 208, 0.53);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

#scrollTop:hover {
    background-color: rgba(165, 165, 165, 0.52);
}

.separator {
    height: 1px;
    width: 100%;
    margin: 10px 0;
}

.header {
    font-size: max(3rem, 4.5vmax);
    text-align: center;
    margin-bottom: -4vh;
    font-weight: 350;
}