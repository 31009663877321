.svg-map__location {
    transition: all 0.3s ease-in-out;
    transform-origin: center center 0;
    transform-box: fill-box;
}

.svg-map__location:hover {
    transform: scale(1.05);
    fill: #4D0000 !important;
    stroke: #eeeeee;
}

.svg-map {
    height: 100% !important;
    width: auto;
    display: block;
}